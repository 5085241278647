var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sg-web-content-2"},[_c('img',{staticClass:"sg-w100",attrs:{"src":"https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/star.png"}}),_c('el-row',{staticClass:"sg-border-bottom"},[_c('el-col',{attrs:{"sm":{ span: 14, offset: 5 }}},[_c('div',{staticClass:"sg-flex-center"},[_c('div',{staticClass:"sg-space-between sg-bold sg-gray-b1 sg-font-14px",staticStyle:{"width":"1152px"}},[_c('div',{staticClass:"sg-flex-center"},[_c('i',{staticClass:"el-icon-s-home sg-orange"}),_c('i',{staticClass:"el-icon-caret-right sg-pad",staticStyle:{"padding":"0 11px"}}),_c('div',[_vm._v("关于正启之星")]),_c('i',{staticClass:"el-icon-caret-right sg-pad",staticStyle:{"padding":"0 11px"}}),_c('div',[_vm._v("新闻中心")])]),_c('div',{staticClass:"sg-flex-center sg-gray-6a"},_vm._l((_vm.tabs),function(item,index){return _c('div',{key:index,class:{
                'sg-tab sg-pointer sg-hover sg-nav-ani': true,
                'sg-selected': _vm.tab == item.key,
              },style:({
                'margin-left': '30px',
                'line-height': _vm.scrollNavDown ? '80px' : '110px',
                height: _vm.scrollNavDown ? '82px' : '112px',
              }),on:{"click":function($event){return _vm.goTab(item.key)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)])])])],1),_c('el-row',[_c('el-col',{staticClass:"sg-pad-left",attrs:{"sm":{ span: 12, offset: 6 }}},[_c('div',{staticClass:"sg-pad-top-lg sg-pad-bottom-lg sg-pad-lr"},[(_vm.list.length > 0)?_c('div',[_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"sg-space-between sg-pad sg-border-bottom sg-new-hover sg-pointer",on:{"click":function($event){return _vm.goLink('/article/' + item.id)}}},[_c('div',{staticClass:"sg-align-center"},[_c('img',{staticClass:"sg-img-md sg-margin-right sg-badge-xs",attrs:{"src":item.cover}}),_c('div',[_c('div',{staticClass:"sg-gray-59504c sg-font-20px"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"sg-gray-3c3c48b3 sg-font-14px sg-margin-top-sm"},[_vm._v(" "+_vm._s(item.desc)+" ")]),_c('div',{staticClass:"sg-margin-top-sm sg-gray-ceced1 sg-font-13px"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.created_at))+" ")])])]),_c('i',{staticClass:"el-icon-arrow-right sg-icon-font sg-gray-59504c sg-pad-left-lg"})])}),_c('div',{staticClass:"sg-pagination"},[_c('el-pagination',{attrs:{"current-page":_vm.current,"page-size":_vm.pageSize,"layout":"total, prev, pager, next, jumper","total":_vm.total},on:{"current-change":_vm.pageChange}})],1)],2):_c('div',{staticClass:"sg-gray-59504c sg-font-20px"},[(_vm.myloading)?_c('div',[_vm._v(" 加载中 ")]):(_vm.keyword)?_c('div',[_vm._v(" 没有找到 "),_c('b',[_vm._v(_vm._s(this.keyword))]),_vm._v(" 相关内容 ")]):_c('div',[_vm._v("没有相关内容")])])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }