<template>
  <div class="sg-web-content-2">
    <img src="https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/star.png" class="sg-w100" />
    <el-row class="sg-border-bottom">
      <el-col :sm="{ span: 14, offset: 5 }">
        <div class="sg-flex-center">
          <div
            class="sg-space-between sg-bold sg-gray-b1 sg-font-14px"
            style="width: 1152px"
          >
            <div class="sg-flex-center">
              <i class="el-icon-s-home sg-orange"></i>
              <i class="el-icon-caret-right sg-pad" style="padding: 0 11px"></i>
              <div>关于正启之星</div>
              <i class="el-icon-caret-right sg-pad" style="padding: 0 11px"></i>
              <div>新闻中心</div>
            </div>
            <div class="sg-flex-center sg-gray-6a">
              <div
                :class="{
                  'sg-tab sg-pointer sg-hover sg-nav-ani': true,
                  'sg-selected': tab == item.key,
                }"
                v-for="(item, index) in tabs"
                :key="index"
                @click="goTab(item.key)"
                :style="{
                  'margin-left': '30px',
                  'line-height': scrollNavDown ? '80px' : '110px',
                  height: scrollNavDown ? '82px' : '112px',
                }"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :sm="{ span: 12, offset: 6 }" class="sg-pad-left">
        <div class="sg-pad-top-lg sg-pad-bottom-lg sg-pad-lr">
          <div v-if="list.length > 0">
            <div
              v-for="(item, index) in list"
              :key="index"
              class="sg-space-between sg-pad sg-border-bottom sg-new-hover sg-pointer"
              @click="goLink('/article/' + item.id)"
            >
              <div class="sg-align-center">
                <img
                  :src="item.cover"
                  class="sg-img-md sg-margin-right sg-badge-xs"
                />
                <div>
                  <div class="sg-gray-59504c sg-font-20px">
                    {{ item.title }}
                  </div>
                  <div class="sg-gray-3c3c48b3 sg-font-14px sg-margin-top-sm">
                    {{ item.desc }}
                  </div>
                  <div class="sg-margin-top-sm sg-gray-ceced1 sg-font-13px">
                    {{ item.created_at | formatDate }}
                  </div>
                </div>
              </div>
              <i
                class="el-icon-arrow-right sg-icon-font sg-gray-59504c sg-pad-left-lg"
              ></i>
            </div>
            <div class="sg-pagination">
              <el-pagination
                @current-change="pageChange"
                :current-page="current"
                :page-size="pageSize"
                layout="total, prev, pager, next, jumper"
                :total="total"
              >
              </el-pagination>
            </div>
          </div>
          <div v-else class="sg-gray-59504c sg-font-20px">
             <div v-if="myloading">
              加载中
            </div>
            <div v-else-if="keyword">
              没有找到&nbsp;<b>{{ this.keyword }}</b
              >&nbsp;相关内容
            </div>
            <div v-else>没有相关内容</div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Tempalte",
  props: {},
  data: () => {
    return {
      tab: "news",
      tabs: [{ name: "观点文章", key: "news" }],
      list: [],
      total: 0,
      pageSize: 15,
      current: 1,
      keyword: "",
      myloading: true
    };
  },
  computed: {
    ...mapState({
      scrollNavDown: (state) => state.scrollNavDown,
      loading: (state) => state.loading
    }),
    totalPages: () => {
      if (this.total) return Math.ceil(this.total / this.pageSize);
      else return 1;
    },
  },
  mounted() {
    this.tab = this.$route.params.tab ? this.$route.params.tab : "intro";
    this.keyword = this.$route.query.k ? this.$route.query.k : "";
    this.getList();
  },
  methods: {
    goLink(url) {
      window.location = url;
    },
    getList() {
      let keyword = this.keyword == '新闻' ? '' : this.keyword
      let that = this
      that.myloading = true
      this.$util.getList2(this, "articles/get", {
        page: this.current,
        title: keyword,
      }, false, function() {
          that.myloading = false
      });
    },
    pageChange(current) {
      this.current = current;
      this.getList();
    },
    goTab(tab) {
      this.tab = tab;
    },
    imgChange(index) {
      this.core_index = index;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/global.scss";
.sg-tab:hover,
.sg-tab.sg-selected {
  color: $red;
  border-bottom: 2px solid $red;
}
.sg-index-line {
  width: 50px;
  height: 3px;
  background: $red;
}
.sg-red-border-right {
  border-right: 2px solid $red;
}
.sg-desc {
  color: gray;
  line-height: 1.6em;
}
.sg-dash-border {
  border-top: 1px dashed gray;
}
.sg-hover-gray:hover {
  background: $gray-4;
}
.sg-core-bg {
  background-image: url("https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/core-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.sg-core-txt-box {
  width: 300px;
  position: absolute;
  right: 0;
  top: 40%;
}
</style>
